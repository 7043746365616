import React from "react";
import { CgProfile } from "react-icons/cg";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = () => {
  window.onscroll = function () {
    scrollFunction();
  };

  const openNav = () => {
    document.getElementById("mySidenav2").style.width = "250px";
    console.log("clicked");
    var element = document.getElementById("mySidenav");
    element.classList.remove("sidenav-remove");
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 10 ||
      document.documentElement.scrollTop > 10
    ) {
      document.getElementById("navbar").style.padding = "30px 10px";
      document.getElementById("logo").style.fontSize = "25px";
    } else {
      document.getElementById("navbar").style.padding = "80px 10px";
      document.getElementById("logo").style.fontSize = "35px";
    }
  }

  return (
    <div>
      <div className="navbar ">
        <div className="d-flex justify-content-between align-items-center w-100 ">
          <div className="nav-brand d-flex justify-content-center align-items-center">
            <div className="span-nav d-flex justify-content-center align-items-center" onClick={openNav}>
              <GiHamburgerMenu />
            </div>
            <a className="" href="#">HotelsOffline</a>
          </div>

          <div className="nav-lists">
            <ul>
              <li>
                <a href="#">Business</a>
              </li>
              <li>
                <a href="#">Trips</a>
              </li>
              <li>
                <button>
                  {" "}
                  <CgProfile /> Sign In
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
