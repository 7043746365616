import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import img from "../assets/img.jpeg";
import img2 from "../assets/undraw-1.svg";
import app from "../assets/undraw-2.svg";
import app2 from "../assets/undraw-6.svg";
import { FaSearch } from "react-icons/fa";

import Glide, {
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm";
import Footer from "../components/Footer";



const Home = () => {
  new Glide(".glide", {
    type: "carousel",
    startAt: 0,
    perView: 3,
  });
  return (
    <div>
      <div id="main">
        <Navbar />

        <div className="container">
          <div className="main-heading">
            <h1>Find & Book hotels & Services.</h1>
          </div>
          <div className="main-form d-flex align-items-center justify-content-between">
            <input placeholder="Lagos (LOS)" type="text" name="" id="" />
            <input placeholder="Anywhere" type="text" name="" id="" />
            <input type="date" name="" id="" />
            <input type="date" name="" id="" />
            <button>
              <FaSearch />
            </button>
          </div>

          <div className="destination mt-5">
            <h3>Destinations you can go to now</h3>
            <p>Popular destinations open to most visitors from Nigeria</p>

            <div class="flex-case row">
              <div className="flex-item col-md-3">
                <div className="flex-body">
                  <img src={img} alt="" />
                </div>
                <h2>Portuga</h2>
                <p>COVID-19 test required</p>
                <p> Vaccinated travelers can visit</p>
              </div>
              <div className="flex-item col-md-3">
                <div className="flex-body">
                  <img src={img} alt="" />
                </div>
                <h2>Ireland</h2>
                <p> Vaccinated travelers can visit</p>
                <p> Masks recommended</p>
              </div>
              <div className="flex-item col-md-3">
                <div className="flex-body">
                  <img src={img} alt="" />
                </div>
                <h2>Costa Rica</h2>
                <p> Vaccinated travelers can visit</p>
                <p> Masks recommended</p>
              </div>
              <div className="flex-item col-md-3">
                <div className="flex-body">
                  <img src={img} alt="" />
                </div>
                <h2>Switzerland</h2>
                <p>Vaccinated travelers can visit</p>
                <p> Masks recommended</p>
              </div>
            </div>
          </div>

          <div className="form mt-5 d-flex align-items-center justify-content-center border p-3 w-100">
            <div className="div-text flexer">
              <h2>Members access great rates and savings</h2>
              <p>
                Sign up for access to personalized recommendations and Private
                Deals.
              </p>
            </div>

            <div className="div-img flexer  d-flex align-items-center justify-content-center ">
              <img src={img2} width="55%" alt="" />
            </div>

            <div className="div-form flexer">
              <div className="form-inner">
                <form action="">
                  <input
                    placeholder="Enter your email address"
                    type="text"
                    className="mr-3"
                  />
                  <button>Lets do this</button>
                </form>
              </div>
            </div>
          </div>

          <div className="popular-cities mt-5">
            <div className="cities-head">
              <h2>Get the HotelsOffline app</h2>
              <p>Enjoy benefits only available in our award winning app</p>
            </div>

            <div className="cities-body">
              <div className="row">
                <div className="col-md-6">1
                  <div className="col-img mt-5">
                    <img width="100%" src={app} alt="" />
                  </div>
                  <h4 className="mt-5">Get our best deals with Mobile Rates</h4>
                </div>
                <div className="col-md-6">
                  <div className="col-img mt-5">
                    <img width="65%" src={app2} alt="" />
                  </div>
                  <h4 className="mt-5">
                    Get notified when prices drop for trips you’re planning
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <div className="popular-cities mt-5">
            <div className="cities-head">
              <h2>Top 8 popular cities</h2>
              <p>See what's popular with other travelers</p>
            </div>

            <div className="cities-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="col-img">
                    <img width="100%" src={img} alt="" />
                  </div>
                  <h4 className="mt-3">New York, NY</h4>
                </div>
                <div className="col-md-6">
                  <div className="col-img">
                    <img width="100%" src={img} alt="" />
                  </div>
                  <h4 className="mt-3">Chicago, IL</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="planning mt-5">
            <div className="planning-headers">
              <h2>Start your travel planning here</h2>
              <p>Search Hotels & Rental Cars</p>
            </div>
            <div className="planning-body">
              <div className="row">
                <div className="col-md-4">
                  <ul>
                    <li>Las Vegas</li>
                    <li>Honolulu</li>
                    <li>Toronto</li>
                    <li>Paris</li>
                    <li>New York</li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul>
                    <li>Cancún</li>
                    <li>Rome</li>
                    <li>San Juan</li>
                    <li>Miami Beach</li>
                    <li>Montego Bay</li>
                  </ul>
                </div>
                <div className="col-md-4">
                  <ul>
                    <li>Punta Cana</li>
                    <li>Key West</li>
                    <li>Orlando</li>
                    <li>Barcelona</li>
                    <li>Hawaii</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Home;
