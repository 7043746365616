import React, { useState, useEffect } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineFlight, MdOutlineHealthAndSafety } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { IoIosBed } from "react-icons/io";
import { ImCancelCircle } from "react-icons/im";
import { BiDirections, BiTrain, BiWorld } from "react-icons/bi";
import { FaUmbrellaBeach, FaCar } from "react-icons/fa";
const Sidebar = () => {
  const [nav, setNav] = useState(true);

  useEffect(() => {
    toggle();
  }, []);

  const toggle = () => {
    setNav(!nav);
    if (nav) {
      openNav();
    } else {
      closeNav();
    }
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("main").style.marginLeft = "250px";
    var element = document.getElementById("mySidenav");
    element.classList.remove("sidenav-remove");
  };

  function closeNav() {
    document.getElementById("mySidenav").style.width = "60px";
    document.getElementById("main").style.marginLeft = "60px";
    var element = document.getElementById("mySidenav");
    element.classList.add("sidenav-remove");
  }
  function closeNav2() {
    document.getElementById("mySidenav2").style.width = "0px";
    var element = document.getElementById("mySidenav");
    element.classList.add("sidenav-remove");
  }
  return (
    <div>
      <div id="mySidenav" class="sidenav">
        <span onClick={toggle}>
          <GiHamburgerMenu />
        </span>
        <div className="div1 mt-3">
          <ul>
            <li onClick={openNav}>
              <CgProfile /> <a href="#">Sign in</a>
            </li>
          </ul>
          <hr />
        </div>
        <ul>

          <li onClick={openNav}>
            <IoIosBed /> <a href="#">Hotels</a>
          </li>
         
          <li onClick={openNav}>
            <BiDirections /> <a href="#">Things to do</a>
          </li>
          <li onClick={openNav} className="active">
            <FaUmbrellaBeach /> <a href="#">Packages</a>
          </li>
          <li onClick={openNav} >
            <CgProfile /> <a href="#">Services</a>
          </li>
          
          <hr />
          <li onClick={openNav}>
            <BiWorld /> <a href="#">Explore</a>
          </li>
         
          <li onClick={openNav}>
            <MdOutlineHealthAndSafety /> <a href="#">Contact us</a>
          </li>
        </ul>

        <hr />
        <ul>
          <li onClick={openNav}>
            <MdOutlineHealthAndSafety /> <a href="#">Trips</a>
          </li>
        </ul>
      </div>
      <div id="mySidenav2" class="sidenav">
        <span className=" side-nav-btn d-flex justify-content-end align-1items-center" onClick={closeNav2}>
          <ImCancelCircle />
        </span>
        <div className="div1 mt-3">
          <ul>
            <li onClick={openNav}>
              <CgProfile /> <a href="#">Sign in</a>
            </li>
          </ul>
          <hr />
        </div>
        <ul>

          <li onClick={openNav}>
            <IoIosBed /> <a href="#">Hotels</a>
          </li>
         
          <li onClick={openNav}>
            <BiDirections /> <a href="#">Things to do</a>
          </li>
          <li onClick={openNav} className="active">
            <FaUmbrellaBeach /> <a href="#">Packages</a>
          </li>
          <li onClick={openNav} >
            <CgProfile /> <a href="#">Services</a>
          </li>
          
          <hr />
          <li onClick={openNav}>
            <BiWorld /> <a href="#">Explore</a>
          </li>
        
          <li onClick={openNav}>
            <MdOutlineHealthAndSafety /> <a href="#">Contact us</a>
          </li>
        </ul>

        <hr />
        <ul>
          <li onClick={openNav}>
            <MdOutlineHealthAndSafety /> <a href="#">Trips</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
