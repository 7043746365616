import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="footer mt-5">
        <div className="container">
    
         <div className="normal-footer">
            <h4>
            © 2022 HotelsOffline
            </h4> 
          </div>  
      
        </div>
      </div>
    </div>
  );
};

export default Footer;
